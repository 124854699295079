import React from "react";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Contact.css";

function Contact() {
  return (
    <section id="contact">
      <div className="contact-header">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-body">
        <h1>Looking for something special?</h1>
        <p>
          Do you have a large or special order that you need to make? Tell us
          what you're looking for, and we'll get back to you soon with a quote
          for price and availability.
        </p>
        <div className="whatsapp-btn">
          <Button
            variant="contained"
            startIcon={<WhatsAppIcon />}
            color="success"
            sx={{textTransform:'none'}}
            size="large"
            href="https://wa.me/+919822297117"
          >
            Message us on Whatsapp
          </Button>
        </div>
        <h2>Nanotech Spline Gauges</h2>
        <h2>Timings</h2>
        <p className="time">Monday - Friday: 9am - 5pm</p> 
        <p className="time">Saturday: 10am - 4pm</p> 
        <p className="time">Sunday: Closed</p>
        <div className="map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d336.56651934074114!2d75.24068746568224!3d19.830487740103717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDQ5JzQ5LjkiTiA3NcKwMTQnMjYuNiJF!5e0!3m2!1sen!2sin!4v1678713834540!5m2!1sen!2sin" width="100%" height="500" style={{border:0}} title='Nanotech Spline Gauges'></iframe>
        </div>
      </div>
    </section>
  );
}

export default Contact;
