import React from "react";
import "./About.css";
import aboutImg from "../images/about-img.png";
import ringGauge from "../images/SplineRingGuage.jpeg";
import plugGauge from "../images/PlugGuageGo.jpeg";
import splineMandrel from "../images/SplineMandrel.jpeg";
import splineBroach from "../images/SplineBroaches.jpeg";
import specialGauge from "../images/SpecialGuage2.jpeg";
import { motion } from "framer-motion";

const splinegauges = [
  {
    name: "Ring Gauge",
    info: "A ring gauge, is a cylindrical ring of a thermally stable material, often steel, whose inside diameter is finished to gauge tolerance and is used for checking the external diameter of a cylindrical object. Ring gauges are used for comparative gauging as well as for checking, calibrating, or setting of gauges or other standards.",
    img: ringGauge,
    reverse: true,
  },
  {
    name: "Plug Gauge Go & No Go",
    info: "Spline Gauges are used for checking External & Internal spline, respectively. Checking individual parameters like profile accuracy, indexing accuracy, and pcd runout of splined components on a production line is time-consuming and not practical. Therefore checking dimensional and geometrical accuracies of External & internal spline by a set of go & nogo gauges is simple and reliable.",
    img: plugGauge,
    reverse: false,
  },
  {
    name: "Spline Mandrels",
    info: "We can offer taper spline mandrels for the inspection of parts by locating them on splines. Also, for carrying out finish machining operation by locating on splines. Use of such mandrels ensures the geometrical accuracies of splined components.",
    img: splineMandrel,
    reverse: true,
  },
  {
    name: "Spline Broaches",
    info: "We manufacture the best quality broaching tools. These include internal broaches for round, straight sided spline, involute spline, serration, any special profile and key way profiles. Also, surface broaches for any surface broaching application. In addition to this, we will suggest a special broaching application for better productivity. Our experience of more than 20 years in designing, manufacturing and quality control goes into every broach produced by us.",
    img: splineBroach,
    reverse: false,
  },
  {
    name: "Special Gauges",
    info: "In this, we can check different geometrical Tolerance & Parameter. We also get to check position, PCD, CD, Squareness, Flatness, Parallelism, Straightness, Face run out, ID/OD Run out, circulatiry, Concentricity, Symmetricity, Depth, Groove ID/OD, etc...",
    img: specialGauge,
    reverse: true,
  },
];

function About() {
  return (
    <section id="about">
      <div className="about-header">
        <h1>About Us</h1>
      </div>
      <div className="about-body">
        <p>
          We would like to introduce Nanotech Spline Gauges Pvt. Ltd. as a
          manufacturer of Spline Gauges and Special Gauges located in MIDC Waluj
          Aurangabad, Maharashtra. Nanotech Spline Gauges Pvt. Ltd. was
          established on September 30th, 2022, by experienced individuals in the
          field of manufacturing Spline Gauges and Special Gauges. We cater to
          the needs of the automobile industry and gear manufacturing industries
          in India. Our team has extensive experience in designing,
          manufacturing, and servicing spline gauges and special gauges. We
          provide our customers with a single source for all their spline gauge
          and special gauge needs. Our commitment is to achieve total customer
          satisfaction through quality, on-time service, and cost-effectiveness.
        </p>
        <div className="about-img">
          <img src={aboutImg} alt="img"></img>
        </div>
      </div>
      <div className="what-we-manufacture">
        <h1>What we Manufacture ?</h1>
        <div className="splinegauges-info">
          {splinegauges.map((gauge) => {
            return (
              <motion.div key={gauge.name}
                initial={{ opacity: 0 }}
                viewport={{ margin: "-150px" }}
                whileInView={{ opacity: 1, scale: 1.03 }}
                transition={{ ease: "easeInOut", duration: 1 }}
                className={`spline-info-with-img ${
                  gauge.reverse ? "reverse" : ""
                }`}
              >
                <div className="spline-info">
                  <h1>{gauge.name}</h1>
                  <p>{gauge.info}</p>
                </div>
                <div className="spline-img">
                  <img src={gauge.img} alt="img"></img>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default About;
