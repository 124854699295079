import React from "react";
import { motion } from "framer-motion";
import Factory from "../images/factory.svg";
import "./Body.css";

function Body() {
  return (
    <div className="body" id="body">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1.2, times: [0.3] }}
        className="body-text"
      >
        <p>
          Welcome to Nanotech Spline Gauges, your trusted partner in precision
          manufacturing. At Nanotech Spline Gauges, we specialize in crafting a
          diverse range of high-quality gauges, including spline gauges and
          spline mandrels. Our commitment to excellence and cutting-edge
          technology sets us apart in the industry. With a focus on precision
          engineering, we deliver products that meet the highest standards of
          accuracy and reliability. Whether you're in need of specialized spline
          gauges or custom solutions, Nanotech Spline Gauges is your go-to
          source for quality and innovation. Explore our range of products and
          experience the assurance that comes with precision-crafted gauges
          tailored to your unique requirements. Welcome to a world where
          precision meets perfection.
        </p>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ ease: "easeInOut", duration: 1.5 , times: [0.3]}}
        className="factory-img"
      >
        <img src={Factory} alt="factoryimg"></img>
      </motion.div>
    </div>
  );
}

export default Body;
