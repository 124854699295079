import './App.css';
import About from './components/About';
import Body from './components/Body';
import Cardsdiv from './components/CardsDiv';
import Contact from './components/Contact';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Body/>
      <Cardsdiv/>
      <About/>
      <Contact/>
    </div>
  );
}

export default App;
