import { motion } from "framer-motion";
import "./CardsDiv.css";
import cir1 from "../images/CircleImage1.png";
import cir2 from "../images/CircleImage2.png";
import cir3 from "../images/CircleImage3.png";

function Cardsdiv() {
  const cardsdata = [
    {
      img: cir1,
      heading: "Our Quality Promise",
      info: "Whether you're coming in for Spline gauges, mandrels, or special gauges, we promise that you will be completely satisfied with our service.",
    },
    {
      img: cir2,
      heading: "We Have You Covered",
      info: "We're committed to providing a stress-free experience to both new and returning customers. Feel free to contact us for any type of service.",
    },
    {
      img: cir3,
      heading: "Knowledgable Staff",
      info: "The staff at our company has great experience in the field. We are dedicated to providing high-quality services for each of our customers.",
    },
  ];

  return (
    <>
      <motion.section
        className="cardsdiv"
        id="cardsdiv"
        initial={{ opacity: 0 , scale: 0.8}}
        viewport={{ margin: "-150px" }}
        whileInView={{ opacity: 1 , scale: 1}}
        transition={{ ease: "easeInOut", duration: 1 }}
      >
        {cardsdata.map((cardsdata) => {
          return (
            <div key={cardsdata.heading}>
              <div className="card">
                <div className="card-img">
                  <img src={cardsdata.img} alt="img"></img>
                </div>
                <div className="card-heading">
                  <h2>{cardsdata.heading}</h2>
                </div>
                <div className="card-info">
                  <p>{cardsdata.info}</p>
                </div>
              </div>
            </div>
          );
        })}
      </motion.section>
    </>
  );
}

export default Cardsdiv;
